import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Col } from "react-bootstrap";
import { MdDelete as DeleteIcon } from "react-icons/md";

function FileSelector({
  label,
  name,
  required,
  onChange,
  error,
  disabled,
  accept,
  ...props
}) {
  console.log("accept",accept);
  return (
    <div className="file-selector">
      {label && (
        <label className="file-selector-label" htmlFor={name}>
          {label}
          {required && <span className="required-indicator"></span>}
        </label>
      )}
      <div className="file-input-container">
        <input
          type="file"
          className="file-input"
          onChange={onChange}
          name={name}
          required={required}
          disabled={disabled}
          accept={accept}
          {...props}
        />
        <FontAwesomeIcon icon={faPaperclip} className="fa-fw file-clip-icon" />
      </div>
      {error && <span className="error-message">{error}</span>}
    </div>
  );
}
function DummyFile({
  fileName = "",
  showDelete = false,
  showDownload = false,
  onDownload = () => {},
  onDelete = () => {},
  label,
  disabled=false
}) {
  return (
    <Col className="dummy-file-container pb-2">
      {label && <span className="dummy-file-label">{label}</span>}
      <div className="dummy-file">
        <span className="dummy-file-name px-3">{fileName}</span>
        {showDownload ? (
          <FontAwesomeIcon
            size={"1.25rem"}
            style={{
              color: "#3D5CB8",
              padding: "10px",
              cursor: "pointer",
            }}
            icon={faEye}
            onClick={onDownload}
          />
        ) : null}
        {showDelete ? (
          <button
            type="button"
            className="dummy-file-delete"
            onClick={onDelete}
            disabled={disabled}
          >
            <DeleteIcon size={"1.25rem"} />
          </button>
        ) : null}
      </div>
    </Col>
  );
}

export default FileSelector;
export { DummyFile };
